<template>
    <section class="llamados-ver mt-4 ml-4">
        <cargando v-if="cargando" />
        <div class="row mb-3">
            <div class="col-12">
                <el-breadcrumb separator-class="el-icon-arrow-right" class="f-300">
                    <el-breadcrumb-item :to="{ name: 'correspondencia.listado' }">
                        Correspondencia
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>Reporte</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12 col-lg-5">
                <div class="row">
                    <div class="col-auto my-auto d-middle">
                        <img :src="correspondencia.imagen_icono" alt="" width="35" height="35" class="br-20" />
                        <div class="col-auto my-auto">
                            <p class="f-15 f-500 mx-2">{{ correspondencia.tipo }}</p>
                            <p class="f-13 mx-2">Tipo correspondencia</p>
                        </div>
                    </div>
                    <div class="col-auto ml-auto my-auto">
                        <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="border-0 shadow-sm br-3">
                            <div slot="content" class="text-86">
                                <p class="cr-pointer" @click="retirarLlamado">Eliminar</p>
                            </div>
                            <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer ml-2">
                                <i class="icon-vertical-points" />
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="col-12 mt-2 mt-lg-4">
                        <div class="row">
                            <div class="col-12 d-middle">
                                <div class="wh-32px rounded-circle d-middle-center bg-db">
                                    <i class="icon-navigation f-20 op-05" style="transform: rotate(90deg);" />
                                </div>
                                <div>
                                    <p class="f-15 f-500 mx-2">Entrega:</p>
                                </div>
                                <p class="f-15"> {{ correspondencia.entrega_nombre }} </p>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12 d-middle">
                                <div class="wh-32px rounded-circle d-middle-center bg-phone">
                                    <i class="icon-house-outline f-20 op-05" />
                                </div>
                                <div>
                                    <p class="f-15 f-500 mx-2">{{ correspondencia.vivienda }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12 d-middle">
                                <div class="wh-32px rounded-circle d-middle-center bg-db">
                                    <i class="icon-account-checked-outline f-18 op-05" />
                                </div>
                                <div>
                                    <p class="f-15 f-500 mx-2">Recepción:</p>
                                </div>
                                <p class="f-15"> {{ correspondencia.recepcion }} </p>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12 d-middle mx-1">
                                <i class="icon-calendar f-20" />
                                <div>
                                    <p class="f-15 f-500 mx-2">{{ correspondencia.created_at | helper-fecha('DD MMMM YYYY - h:m a') }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12 d-middle">
                                <p class="f-15 f-500 mx-2">Observaciones</p>
                            </div>
                            <div class="col-12 mx-2">
                                <p class="f-15"> {{ correspondencia.observaciones }} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-5">
                <galeria-cinco ref="gallriaRef" :listado="imagenes" />
            </div>
            <!-- Partials -->
            <modal-eliminar ref="modalEliminarCorrespondencia" titulo="Eliminar reporte" mensaje="¿Desea eliminar este reporte de correspondencia?" tamano="modal-md" class="f-15 text-center" @eliminar="eliminar" />
        </div>
    </section>
</template>
<script>
import Correspondencias from '~/services/correspondencia'
export default {
    data(){
        return{
            loading: false,
            cargando : false,
            imagenes:[],
            correspondencia:{}
        }
    },
    created(){
        this.detalle()
    },
    methods:{
        retirarLlamado(){
            this.$refs.modalEliminarCorrespondencia.toggle()
        },
        async detalle(){
            try {
                this.cargando = true
                const {data} = await Correspondencias.detalle({idCorrespondencia: this.$route.params.idCorrespondencia})
                
                if(data.success){
                    this.correspondencia = data.data
                    this.correspondencia.imagenes.forEach(imagen => {
                        imagen.img = imagen.imagen
                    });
                    this.imagenes = this.correspondencia.imagenes
                    this.$refs.gallriaRef.seleccionarImg(this.imagenes[0].id,this.imagenes[0].img)
                }
            } catch (e){
                this.errorCatch(e)
            } finally{
                this.cargando = false
            }
        },
        async eliminar(){
            try {
                this.cargando = true
                
                const {data} = await Correspondencias.eliminar({id_correspondencia : this.$route.params.idCorrespondencia})
                
                if(data.success){
                    this.$notify({
                        title: 'Eliminar',
                        message: data.mensaje,
                        type: 'success'
                    });
                    this.$refs.modalEliminarCorrespondencia.toggle()
                    this.$router.push({ name: 'correspondencia.listado' })
                }else{
                    this.$notify({
                        title: 'Eliminar',
                        message: data.mensaje,
                        type: 'warning'
                    });
                }
            } catch (e){
                this.errorCatch(e)
            } finally{
                this.cargando = false
            }
        }

    }
}
</script>
<style lang="scss" scoped>
.llamados-ver{
    .vueperslides--2{
        .vueperslide{
            cursor: pointer;
        }
        .vueperslide--active{
            border: 3px solid #109881;
            border-radius: 4px;
        }
    }
    .vueperslide, .vueperslides__parallax-wrapper{
        border-radius: 4px !important;
    }
    .vueperslides__inner .vueperslide.vueperslide--active.vueperslide--visible .vueperslide__content-wrapper{
        border: 3px solid white;
    }
}
</style>