import axios from 'axios'

const API = '/correspondencias'

const Correspondencias = {
    listar(params){
        return axios.get(`${API}/listar`,{params})
    },   
    detalle(params){
        return axios.get(`${API}/detalle`,{params})
    }, 
    eliminar(params){
        return axios.delete(`${API}/eliminar`,{params})
    }, 
    tipos(){
        return axios.get(`${API}/listar-iconos`)
    },
    guardar(payload){
        return axios.post(`${API}`, payload)
    },
}

export default Correspondencias
